$border_focus: solid red;



@mixin shiajo_ippon_container($width) {
  background-color: white;
  padding: 0px;
  width: 2px + $width * 1px; //1 px border
  height: 2px + 60 * $width / 280 * 1px; //for 280 px, size is 60
  margin: auto;
  //float: left;
  border: thin solid black;
}




@mixin shiajo_ippon_row($width) {
  width: 2px + $width * 1px; //1 px border
  padding: 0px;
}

$sizes: 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150;

@each $size in $sizes {
  .shiajo-screen-#{$size} {
    tbody {
      tr.current {
        border: $border_focus;
      }

      tr.shiajo-additional {
        background-color: orange;
        font-size: 14pt;
        font-weight: bold;
      }

      tr.shiajo-information {
        border-top: solid thick;
        font-weight: bold;

        td.teams {
          font-weight: normal;
          vertical-align: left;
          font-size: 34px;
          padding: 0;
        }
      }

      tr {
        td.fighterName {
          vertical-align: middle;
          font-size: xxx-large;
          padding: 0;
        }

        td.fighterteamName {
          vertical-align: middle;
          font-size: xxx-large;
          padding: 0;
        }

        td.fighterteamName-Small {
          vertical-align: middle;
          font-size: xx-large;
          padding: 0;
        }

        td.fightResult {
          vertical-align: middle;
          font-size: x-large;
          padding: 0;
        }

        td.shiajo_screen_row {
          @extend .shiajo_round;
          $rightSpace: 0; //distance right side to display some data
          $ipponwidth: 60*$size/100; //ippon width, standard 70
          padding: 0px;

          div.shiajo_ippon_container {
            //
            @include shiajo_ippon_container($ipponwidth * 4 * 3 - $rightSpace);
            border: none;
          }

          div.shiajo_ippon_row {
            //
            @include shiajo_ippon_row($ipponwidth * 4 * 3 - $rightSpace);
            border: none;
          }

          div.shiajo_ippon {
            /*border: solid black;*/
            height: (30px * $ipponwidth * 4 * 3) / 280;
            width: ((3 * $ipponwidth) - ($rightSpace/4)) * 1px;
            padding: 0px;
            float: left;
            font-size: 28pt;
            font-weight: bold;
            //border: thin solid black;
          }

          div.shiajo_ippon_3 {
            /*border: solid black;*/
            height: (30px * $ipponwidth * 4 * 3) / 280;
            width: ((3 * ($ipponwidth * 4 / 6)) - ($rightSpace/4)) * 1px;
            padding: 0px;
            float: left;
            font-size: 28pt;
            font-weight: bold;
          }

          div.shiajo_ippon_2 {
            /*border: solid black;*/
            height: (30px * $ipponwidth * 4 * 3) / 280;
            width: ((3 * ($ipponwidth * 4 / 2)) - ($rightSpace/4)) * 1px;
            padding: 0px;
            float: left;
            font-size: 28pt;
            font-weight: bold;
          }

          div.shiajo_screen_roundstate {
            width: $rightSpace * 1-15px;
            float: left;
            padding: 0px;
            margin: 0px;
          }

          div.shiajo_screen_ippons {
            width: $ipponwidth * 4 * 3 - $rightSpace * 1px;
            //float: left;
            margin: auto;
            //border: thick black solid;

          }
        }

      }
    }

    table-layout: fixed;
  }
}

.shiajo_round .row {
  margin-right: 0 !important;
}

.shiajo_button_fighter_action {
  min-width: 35px;
}


.shiajo_round {
  .shiajo_ippon_container {
    @include shiajo_ippon_container(280);
  }

  .shiajo_ippon_row {
    @include shiajo_ippon_row(280);
  }

  .shiajo_ippon_container_encho {
    background-image: url(../images/ENCHO.png);
    background-size: cover;
  }

  .shiajo_ippon_container_ikiwake {
    background-image: url(../images/cross.png);
    background-size: cover;
  }

  .shiajo_ippon_hansoku {
    float: left;
    width: 50%;
  }

  .shiajo_ippon_container_rightwin {
    background-image: url(../images/barre.png);
    background-size: cover;
  }

  .shiajo_ippon_container_leftwin {
    background-image: url(../images/barre2.png);
    background-size: cover;
  }

  .shiajo_ippon {
    /*border: solid black;*/
    height: 1 * 30px;
    width: 1 * 70px;
    padding: 0px;
    float: left;
    font-size: 14pt;
    font-weight: bold;
  }

  .shiajo_ippon_3 {
    /*border: solid black;*/
    height: 1 * 30px;
    width: 1 * 45px;
    padding: 0px;
    float: left;
    font-size: 14pt;
    font-weight: bold;
  }

  .shiajo_ippon_2 {
    /*border: solid black;*/
    height: 1 * 30px;
    width: 1 * 35px;
    padding: 0px;
    float: left;
    font-size: 14pt;
    font-weight: bold;
  }
}

th.shiaijoScreenTitle {
  font-size: 96px;
  padding: 0px;
  margin: 0px;
  line-height: 75px;
  vertical-align: middle;
}

th.shiaijoScreenTitleDescription {
  font-size: 30px;
  padding: 0px;
  margin: 0px;
  line-height: 34px;
  
  vertical-align: middle;
}

p.shiaijoScreenTitleDescription-small {
  font-size: 24px;
  padding: 0px;
  margin: 0px;
  line-height: 24px;
  
  vertical-align: middle;
}



table.shiajo_scheduller {
  tbody {
    tr {
      td.shiajo_scheduller_competition {
        max-width: 100px;
      }
    }
  }
}

table.shiajo_fightgroup {
  td.red {
    background-color: orangered;
    color: whitesmoke;
    font-weight: 600;
    border: solid #000;
  }

  td.white {
    background-color: white;
    //color: whitesmoke;
    font-weight: 600;
    border: solid #000;
  }

  td.ippon {
    border: solid #000;
    white-space: "nowrap";
  }
}

/*    */

img.shiajo-ùreload {
  width: 30px;
}

.teamorder {
  tbody {
    tr.order-inactive {
      background-color: lightpink;
    }
  }
}

.shiajo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.shiajo-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.shiajo-modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 80%;
  width: 80%;
  padding: 1rem;
}

.shiajo-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shiajo-modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}

.shiaijo-popover-content {
  min-width: 600px;
}