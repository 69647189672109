@import 'Shiajo.scss';
@import 'Chronometer.scss';
@import 'Draw.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Chrono {
  //font-size: xxx-large
}

.Chrono-Soon {
  color: orange
}

.Chrono-Imminent {
  color: red
}

.cnkdr_content_notfullscreen {
  // position: relative;
  // top: -165px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
  margin-left: 50px;
  margin-right: 50px;
}

.cnkdr_content_fullscreen {
  // position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
}

/* SCREEN */
.shiajo-screen {
  text-transform: uppercase;
  text-align: center;
  font-size: x-large;
}

.shiaijo-screen-welcome h1{
   font-size: 10em;
   margin-bottom: 100px;
}

.shiaijo-screen-welcome h2{
  font-size: 7em;
  
}

.shiaijo-screen-welcome h3{
  font-size: 3em;
  margin-bottom: 50px;
}

.shiaijo-screen-welcome p{
  font-size: 5em;
}

th.team-red {
  background-color: orangered;
  font-weight: bold;
  font-size: xxx-large;
  padding: 0;
}

th.team-white {
  background-color: lightgray;
  font-weight: bold;
  font-size: xxx-large;
  padding: 0;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}

.tree-h1 h1 {
  margin: 10px;
  font-size: 1.5em;
}

.competition-config-paper {
  margin-top: 10px;
  padding: 4px;
}

.screen-poollist-cell {
  
}


th.tree-head-fightgouptype {
  font-size: medium;
  font-weight: bold;
}

.tree-fightgoup-nodelabel {
  font-size: medium;
  font-weight: 600;
}

.tree-fightgoup-status {

}

.tree-fightgoup-shiaijo {
  font-weight: 600;

}

.tree-fightgoup-rank {

}

.tree-fightgoup-moveshiaijo {

}

//SVG
.tree-svg-h1 {
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.tree-svg-content {
  margin: 10px;
}

.screen-poollist-table {
  border: 1px solid black;
}

.screen-white-label {
  color: white;
  -webkit-text-stroke: 1px #0a0a0a;
  text-shadow: 0 0 1px #000;
}

.screen-red-label {
  color: red;
}

.CSVImporter_Importer {
  position: relative;
}

.CSVImporter_FileSelector {
  height: 50px;
  padding: 0;
}

.CSVImporter_ImporterFrame__footer {
  position: absolute;
  right: 150px;
  top: 0px;
}

.react-confirm-alert-overlay {
  z-index: 9999;
}

div.FightGroupCreationModal-label {
  margin-top: 10px;
}