a {
  color: rgb(121, 121, 121);
  text-decoration: none;
}
a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
a:hover {
  color: rgb(255, 38, 0);
  text-decoration: none;
}
a.cnk-link {
  color: rgb(88, 77, 77);
  text-decoration: none;
}
a.cnk-link:visited {
  color: rgb(192, 192, 192);
  text-decoration: underline;
}
a.cnk-link:hover {
  color: rgb(255, 38, 0);
  text-decoration: none;
}
.cnk-text-centered {
  text-align: center!important;
}
.cnk-text-left {
  text-align: left!important;
}
.cnk-text-justified {
  text-align: justify!important;
}
.cnk-paragraph {
  color: #000;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 22px;
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-transform: none;
  text-indent: 0px;
  opacity: 1.00;
}

.cnk-titre-1 {
color: #000;
font-family: 'ArialMT', 'Arial', sans-serif;
font-size: 26px;
font-stretch: normal;
font-style: normal;
font-variant: normal;
font-weight: 400;
letter-spacing: 0;
line-height: 30px;
margin: 0px;
opacity: 1.00;
padding-bottom: 0px;
padding-top: 0px;
text-decoration: none;
text-indent: 0px;
text-transform: none;
}


.cnk-titre-2 {
  color: #000;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0px;
  opacity: 1.00;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-indent: 0px;
  text-transform: none;
}
.cnk-titre-3 {
  color: #000;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0px;
  opacity: 1.00;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-indent: 0px;
  text-transform: none;
}

.cnk-titre-4 {
  color: #000;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0px;
  opacity: 1.00;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-indent: 0px;
  text-transform: none;
}

.cnk-error {
  color: #f14668;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: .75rem;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 25px;
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-transform: none;
  text-indent: 0px;
  opacity: 1.00;
}

.cnk-valide {
  color: #48c774;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: .75rem;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 25px;
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-transform: none;
  text-indent: 0px;
  opacity: 1.00;
}
.cnk-footer {
width: 100%;
margin: 0;
background-color: #DFDBD6;
padding-left: 35px;
padding-right: 35px;
padding-top: 20px;
padding-bottom: 20px;
}

.cnk-text-footer {
color: rgb(88, 77, 77);
font-family: 'ArialMT', 'Arial', sans-serif;
font-size: 12px;
font-stretch: normal;
font-style: normal;
font-variant: normal;
font-weight: 400;
letter-spacing: 0;
line-height: 15px;
opacity: 1.00;
padding-bottom: 0px;
padding-top: 0px;
text-align: center;
text-decoration: none;
text-indent: 0px;
text-transform: none;

}
.cnk-text-middle {
vertical-align: middle;
}

.cnk-entete {
vertical-align: middle;
padding-bottom: 5px;
padding-top: 5px;
padding-left: 30px;
padding-right: 30px;
background-color: #F0F1F1;
}

.cnk-img-entete {
margin: 0px;
padding-bottom: 0px;
padding-top: 0px;
padding-left: 30px;
padding-right: 30px;
height: 80px;
vertical-align:middle;
}

.cnk-label {
  color: #000;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-transform: none;
  text-indent: 0px;
  opacity: 1.00;
}

.table-borderless {
border:none;
}

.cnk-padding-left {
padding-left: 60px;
}

.celluleGrise{
background-color: #bfbfbf;
}
.libClub {
color: #595959;
font-size: 12px;
font-style: italic;
}

.libLicencie {
color: #333333;
font-size: 16px;
}

.sautPage {
page-break-after: always;
}

/* Bordure */
.cellule0000 {
border: none;
}
.cellule1111 {
border-top:1px solid black;
border-bottom:1px solid black;
border-right:1px solid black;
border-left:1px solid black;
}
.cellule1000 {
border-left:1px solid black;
}
.cellule0100 {
border-top:1px solid black;
}
.cellule0010 {
border-right:1px solid black;
}
.cellule0001 {
border-bottom:1px solid black;
}
.cellule1100 {
border-left:1px solid black;
border-top:1px solid black;
}

.cellule1001 {
border-left:1px solid black;
border-bottom:1px solid black;
}

.cellule1010 {
border-left:1px solid black;
border-right:1px solid black;
}

.cellule1110 {
border-left:1px solid black;
border-right:1px solid black;
border-top:1px solid black;
}
.cellule1011 {
border-left:1px solid black;
border-right:1px solid black;
border-bottom:1px solid black;
}
.cellule0101 {
border-top:1px solid black;
border-bottom:1px solid black;
}
.cellule0110 {
border-top:1px solid black;
border-right:1px solid black;
}
.cellule0111 {
border-top:1px solid black;
border-right:1px solid black;
border-bottom:1px solid black;
}
.cellule0011 {
border-bottom:1px solid black;
border-right:1px solid black;
}

.cellule1101 {
border-top:1px solid black;
border-left:1px solid black;
border-bottom:1px solid black;
}
/*Cellule des poules */
/* Taille */
.tabResultat {
text-align: left;
font-size: 80%;
}
.tablePolice { font-size: 50%; }

.tabPolice8{
text-align: left;
  font-size: 4px;
}
.cellule10{
text-align: center;
height:10px;
line-height: normal;
font-weight:normal;
}

.cellule15{
text-align: center;
height:15px;
line-height: normal;
font-weight:normal;
}

.cellule20{
text-align: center;
height:20px;
line-height: normal;
font-weight:normal;
}

.cellule25{
text-align: center;
height:25px;
line-height: normal;
font-weight:normal;
}

.cellule30{
text-align: center;
height:30px;
line-height: normal;
font-weight:normal;
}

.cellule35{
text-align: center;
height:35px;
line-height: normal;
font-weight:normal;
}

.cellule40{
text-align: center;
height:40px;
line-height: normal;
font-weight:normal;
}

.cellule50{
text-align: center;
height:50px;
line-height: normal;
font-weight:normal;
}

.cellule60{
text-align: center;
height:60px;
line-height: normal;
font-weight:normal;
}

.cnk-padding-top-10 {
padding-top: 10px;
}

div.cnk-print-portrait {
display: inline-block;  
max-width: 1654px; 
max-height: 2339px;
margin-top: 10px;
margin-bottom: 10px;
margin-left: 20px;
margin-right: 20px;
/*border: 3px solid #73AD21;*/
padding: 0px;
}

div.cnk-print-paysage {
display: inline-block;  
max-width: 2339px;
max-height: 1654px;
margin-top: 20px;
margin-bottom: 20px;
margin-left: 40px;
margin-right: 40px;
/*border: 3px solid #73AD21;*/
padding: 0px;

}

.cnk-poule-tiers {
/*flex:none;*/
width:33.3333%;
padding-top: 10px;
padding-bottom: 10px;
}
.conteneur-grid{
display: grid;
grid-template-columns: 1fr 2fr 3fr;
border: 2px solid red;
background-color: #DDD;
}
.conteneur-grid > div{
padding: 20px 0px;
background-color: #ED8;
border: 1px dashed black;
}


.cnk-tirage {
color: #19176d;
font-family: 'ArialMT', 'Arial', sans-serif;
font-size: 26px;
font-stretch: normal;
font-style: normal;
font-variant: normal;
line-height: 25px;
margin: 0px;
padding-bottom: 0px;
padding-top: 0px;
text-decoration: none;
text-transform: none;
text-indent: 0px;
opacity: 1.00;
}

@media print {
  .printPageDraw {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .printPagesDraw {
    height: 100%; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

.draw_importer {
  .CSVImporter_Importer {
    width: 100%;
  }
}